import React from "react"
import Helmet from "react-helmet"
import styled from "styled-components"

import Header from "../components/Header"
import Footer from "../components/Footer"

import Theme from "../components/Theme"

const Section = styled.div`
  margin-top: 64px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  justify-content: space-around;
  max-width: 1080px;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
  min-height: 100vh;
`

const Text = styled.div`
  flex: 1;
  strong {
    color: #fa824c;
    font-weight: normal;
  }
  margin-bottom: 16px;
`

const LegalPage = () => {
  return (
    <Theme>
      <Helmet>
        <meta charSet="utf-8" />
        <title defer={false}>Pharmille - Mentions légales</title>
        <meta
          name="description"
          content="Pharmille simplifie la gestion du personnel de pharmacie."
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <Header bg="#08415C" />
      <Section>
        <Text>
          <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/dc891767-2a21-4a08-b526-c1d32cd1060c/cd.js"
            type="text/javascript"
            async
          />
        </Text>
      </Section>
      <Footer />
    </Theme>
  )
}

export default LegalPage
